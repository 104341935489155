<template>
    <v-app id="404">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <div class="mr-3 hidden-sm-and-down">
            <img :src="getImgDeny" alt="">
          </div>          
          <div class="text-md-center">
            <h1>403</h1>
            <h2 class="my-3 headline ">Sorry, access denied.</h2>
            <div>
              <v-btn color="primary" @click="goHome">Go Home</v-btn>
            </div>  
          </div>
        </v-layout>
      </v-container>
    </v-app>
</template>

<script>
import imgDeny from '@/assets/403.svg'
export default {
computed: {
    getImgDeny() {
        return imgDeny;
    }
},

methods: {
    goHome () {
      this.$router.push({ path: '/' });
    }
  }   
}
</script>

<style scoped>
    h1 {
        font-size: 150px;
        line-height: 150px;
        font-weight: 700;
        color: #252932;
        text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;    
    }
</style>


